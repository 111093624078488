let termsModalElement = document.getElementById("termsModal");
if (termsModalElement != undefined) {
	const termsModal = new bootstrap.Modal(termsModalElement);
	termsModal.show();
}

let pwCheck = function (pw1, pw2, pwinvalid) {
	const { passwordStrength } = require("check-password-strength");

	pw1.addEventListener("change", (event) => {
		var ps = passwordStrength(event.target.value);

		if (ps.value == "Too weak" || ps.value == "Weak") {
			var invalidtxt = "Password must ";
			if (ps.length < 8) {
				invalidtxt = invalidtxt + "be at least 8 charcters long and ";
			}
			invalidtxt =
				invalidtxt +
				"contain lowercase, uppercase, symbol and/or number";
			pwinvalid.innerHTML = invalidtxt;
			pw1.classList.add("is-invalid");
		} else {
			pw1.classList.remove("is-invalid");
		}
	});
};

let registrationFrm = document.getElementById("registrationFrm");
let passwordFrm = document.getElementById("passwordFrm");

if (registrationFrm != undefined) {
	let pw1 = document.getElementById("password");
	let pw2 = document.getElementById("password2");
	let pwinvalid = document.getElementById("pwinvalid");
	let pws = pwCheck(pw1, pw2, pwinvalid);

	window.onRegisterSubmit = function () {
		if (pw1.value != pw2.value) {
			pw1.classList.add("is-invalid");
			pw2.classList.add("is-invalid");
			pwinvalid.innerHTML = "Passwords must match.";
		} else if (pw1.value.length > 0) {
			pw1.dispatchEvent(new Event("change"));
		}

		if (
			registrationFrm.checkValidity() &&
			!pw1.classList.contains("is-invalid")
		) {
			registrationFrm.submit();
		}

		registrationFrm.classList.add("was-validated");
	};
} else if (passwordFrm != undefined) {
	let pw1 = document.getElementById("password");
	let pw2 = document.getElementById("password2");
	let pwinvalid = document.getElementById("pwinvalid");
	let pws = pwCheck(pw1, pw2, pwinvalid);

	passwordFrm.addEventListener("submit", (event) => {
		if (pw1.value != pw2.value) {
			pw1.classList.add("is-invalid");
			pw2.classList.add("is-invalid");
			pwinvalid.innerHTML = "Passwords must match.";
		} else if (pw1.value.length > 0) {
			pw1.dispatchEvent(new Event("change"));
		}

		if (
			passwordFrm.checkValidity() &&
			!pw1.classList.contains("is-invalid")
		) {
		} else {
			event.preventDefault();
		}

		passwordFrm.classList.add("was-validated");
	});
}
